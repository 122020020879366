import React, { useState, useEffect } from 'react';

const CountdownApp = () => {
  const [countdown, setCountdown] = useState('');

  useEffect(() => {
    // Установка даты окончания
    const endDate = new Date('August 14, 2023 00:00:00').getTime();

    // Обновление счетчика каждую секунду
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = endDate - now;

      // Вычисление оставшегося времени
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Обновление состояния счетчика
      setCountdown(`${days} дней ${hours} часов ${minutes} минут ${seconds} секунд`);
    }, 1000);

    // Очистка интервала после размонтирования компонента
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <h1>До отпуска моей Ирен 💃🏖🛩</h1>
      <p>{countdown}</p>
      <h2>Держись 🐱</h2>
    </div>
  );
};

export default CountdownApp;
