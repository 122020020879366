
import './App.css';
import Count from './count.jsx'


function App() {
  return (
    <div className="App">
      <header className="App-header">

       <Count>
        
       </Count>
      </header>
    </div>
  );
}

export default App;
